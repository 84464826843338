<template>
    <div class="contents">
        <div class="crm">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-12" v-if="announcementText">
                        <br>
                        <div class="alert alert-success alert-dismissible fade show"
                            style="display: block !important" role="alert">
                            <b> PENGUMUMAN : </b>
                            <br>
                            <p v-html="announcementText"></p>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <br>
                        <div class="card banner-feature banner-feature--2 bg-primary" v-if="!myPackageLoading">
                            <div class="banner-feature__shape">
                                <img src="@/assets/img/svg/Group9010.svg" alt="img" class="svg">
                            </div>
                            <div class="d-flex justify-content-center">
                                <div class="card-body">
                                    <h1 class="banner-feature__heading color-white">
                                        {{ $t('dashboard.upgrade_your_package') }}</h1>
                                    <p class="banner-feature__para color-white">
                                        == {{ myPackage.name }} ==
                                        <br>
                                        {{ myPackage.pesan }} {{ $t('dashboard.message') }}
                                        <br>
                                        {{ myPackage.device }} {{ $t('dashboard.device') }}
                                        <br>
                                        {{ myPackage.customer_service }} {{ $t('dashboard.customer_service') }}
                                        <br>
                                        {{ myPackage.campaign }} {{ $t('dashboard.campaign') }}
                                        <br>
                                        {{ myPackage.link_rotator }} {{ $t('dashboard.link_rotator') }}
                                        <br>
                                        {{ myPackage.chat_widget }} {{ $t('dashboard.chat_widget') }}
                                        
                                    </p>
                                    <div class="banner-feature__para color-white">
                                        <p> {{ $t('dashboard.your_package_expired_at') }} <span
                                                class="badge badge-danger">{{ myPackage.expired_at }}</span>
                                        </p>
                                    </div>
                                    <a class="banner-feature__btn btn color-primary btn-md px-20 bg-white radius-xs fs-15"
                                        type="button" href="">{{ $t('global.buy') }} / {{ $t('global.extend') }}</a>
                                </div>
                            </div>
                        </div>
                        <div class="card banner-feature banner-feature--2 bg-primary" v-else>
                            <center>
                                <div class="spinner-border spinner" style="margin-top:20px;color:white" role="status">
                                </div>
                            </center>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row">
                            
                            <div class="col-md-9">
                                <div class="breadcrumb-main">
                                    <h4 class="text-capitalize breadcrumb-title">{{ $t("dashboard.dashboard") }}</h4>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <br>
                                <v-date-picker class="inline-block h-full" v-model="filter.date" mode="date"
                                    :show-current="true">
                                    <template v-slot="{ inputValue, togglePopover }">
                                        <div>
                                            <input @focus="togglePopover()" :value="inputValue" class="form-control" />
                                        </div>
                                    </template>
                                </v-date-picker>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div class="col-md-6 col-sm-12 col-ssm-12 mb-30">
                        <div class="card card-overview-progress border-0">
                            <div class="card-header border-0">
                                <div class="d-flex justify-content-between w-100 align-items-center">
                                    <h6>{{ $t("dashboard.today_overview") }}</h6>
                                </div>
                            </div>
                            <div class="card-body">
                                <div class="card-progress">
                                    <div class="card-progress__summery d-flex justify-content-between">
                                        <div>
                                            <strong class="color-primary"
                                                id="outgoing">{{ statistics.message_sent }}</strong>
                                            <span>{{ $t("dashboard.today_outgoing") }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-progress">
                                    <div class="card-progress__summery d-flex justify-content-between">
                                        <div>
                                            <strong class="color-primary"
                                                id="incoming">{{ statistics.incoming_message }}</strong>
                                            <span>{{ $t("dashboard.today_incoming") }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 mb-30">
                        <div class="revenue-device-chart">
                            <div class="card broder-0">
                                <div class="card-header">
                                    <h6>{{ $t("dashboard.message_sent") }}</h6>
                                    <div class="card-extra">
                                        <ul class="card-tab-links nav-tabs nav mr-3" role="tablist">
                                            <li>
                                                <a class="active" href="#rb_device-today" data-toggle="tab"
                                                    id="rb_device-today-tab" role="tab"
                                                    aria-selected="true">{{ $t("dashboard.today") }}</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <!-- ends: .card-header -->
                                <div class="card-body">
                                    <div class="tab-content">
                                        <div class="tab-pane fade active show" id="rb_device-today" role="tabpanel"
                                            aria-labelledby="rb_device-today-tab">
                                            <div class="revenue-pieChart-wrap">
                                                <div id="chart-container">
                                                    <Doughnut :chart-options="chartOptions" :chart-data="chartData"
                                                        :width="300" :height="300" />
                                                </div>
                                            </div>
                                            <div class="revenue-chart-box-list">
                                                <div class="revenue-chart-box d-flex flex-wrap align-items-center">
                                                    <div class="revenue-chart-box__Icon order-bg-opacity-success">
                                                        <img class="svg" src="@/assets/img/svg/sent.svg" alt="">
                                                    </div>
                                                    <div class="my-10">
                                                        <div class="revenue-chart-box__data" id="terkirim">
                                                            {{ statistics.message_sent }}
                                                        </div>
                                                        <div class="revenue-chart-box__label">
                                                            {{ $t("dashboard.sent") }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="revenue-chart-box d-flex flex-wrap align-items-center">
                                                    <div class="revenue-chart-box__Icon order-bg-opacity-warning">
                                                        <img class="svg" src="@/assets/img/svg/not-open.svg" alt="">
                                                    </div>
                                                    <div class="my-10">
                                                        <div class="revenue-chart-box__data" id="pending">
                                                            {{ statistics.message_pending }}
                                                        </div>
                                                        <div class="revenue-chart-box__label">
                                                            {{ $t("dashboard.pending") }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="revenue-chart-box d-flex flex-wrap align-items-center">
                                                    <div class="revenue-chart-box__Icon order-bg-opacity-danger">
                                                        <img class="svg" src="@/assets/img/svg/cancel.svg" width="50%"
                                                            alt="">
                                                    </div>
                                                    <div class="my-10">
                                                        <div class="revenue-chart-box__data" id="gagal">
                                                            {{ statistics.message_failed }}
                                                        </div>
                                                        <div class="revenue-chart-box__label">
                                                            {{ $t("dashboard.failed") }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- ends: .card-body -->
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-6">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <div class="row">
                                <div class="col-md-8">
                                    <h4> Click Per Day </h4>
                                </div>
                                <div class="col-md-4">
                                <input type="text" class="form-control" name="tanggal" id="picker2" placeholder="dd/mm/yyyy" required>
                            </div>
                            </div>
                            <hr style="margin-top:5px;margin-bottom:5px">
                            <div class="table-responsive">
                                <table class="table mb-0 table-bordered" id="datatable-click-per-day">
                                    <thead>
                                        <tr class="userDatatable-header">
                                            <th>
                                                <span class="userDatatable-title">Hari</span>
                                            </th>
                                            <th>
                                                <span class="userDatatable-title">Tanggal</span>
                                            </th>
                                            <th>
                                                <span class="userDatatable-title">Jumlah Klik</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <div class="row">
                                <div class="col-md-8">
                                    <h4> Click Per Link </h4>
                                </div>
                            </div>
                            <hr style="margin-top:5px;margin-bottom:5px">
                            <div class="table-responsive">
                                <table class="table mb-0 table-bordered" id="datatable-click-per-link">
                                    <thead>
                                        <tr class="userDatatable-header">
                                            <th>
                                                <span class="userDatatable-title">Link</span>
                                            </th>
                                            <th>
                                                <span class="userDatatable-title">Jumlah Klik</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <div class="row">
                                <div class="col-md-8">
                                    <h4> Click Per Rotation </h4>
                                </div>
                            </div>
                            <hr style="margin-top:5px;margin-bottom:5px">
                            <div class="table-responsive">
                                <table class="table mb-0 table-bordered" id="datatable-click-per-rotation">
                                    <thead>
                                        <tr class="userDatatable-header">
                                            <th>
                                                <span class="userDatatable-title">Link</span>
                                            </th>
                                            <th>
                                                <span class="userDatatable-title">No Whatsapp</span>
                                            </th>
                                            <th>
                                                <span class="userDatatable-title">Klik</span>
                                            </th>
                                            <th>
                                                <span class="userDatatable-title">Jumlah Klik</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div> -->
                    <div class="col-xxl-4 col-sm-6 col-ssm-12 mb-30">
                        <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                            <!-- Card 3 -->
                            <div
                                class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                <div>
                                    <div class="overview-content">
                                        <h1 id="contact">{{ contact }}</h1>
                                        <p>{{ $t('global.contact') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-xxl-4 col-sm-6 col-ssm-12 mb-30">
                        <div class="col-xxl-12 col-sm-12 col-ssm-12 mb-30">
                            <div
                                class="ap-po-details ap-po-details--2 p-25 radius-xl bg-white d-flex justify-content-between">
                                <div>
                                    <div class="overview-content">
                                        <h1 id="pesan">0</h1>
                                        <p>Pesan Keluar</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <modal name="Popup" :height="'auto'" :adaptive="true" :scrollable="true">
            <button v-on:click="hidePopup" type="button" class="close element-top-right" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
            <div style="margin: 20px">
                <center>
                    <div v-if="popup.title">
                        <h2> {{ popup.title }} </h2>
                        <br>
                    </div>
                    <div v-if="popup.file">
                        <img :src="popup.file" alt="" width="300px">
                    </div>
                </center>

                <p>{{ popup.text }}</p>
                <br>

            </div>
        </modal>
    </div>
</template>

<script>
    import moment from 'moment'
    import {
        Doughnut
    } from 'vue-chartjs/legacy'
    import {
        Chart as ChartJS,
        Title,
        Tooltip,
        Legend,
        ArcElement,
        CategoryScale
    } from 'chart.js'

    ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

    export default {
        components: {
            Doughnut
        },
        data() {
            return {
                myPackageLoading: true,
                myPackage: {
                    name: "",
                    pesan: "0",
                    device: "0",
                    customer_service: "0",
                    campaign: "0",
                    order_online: "0",
                    link_rotator: "0",
                    chat_widget: "0",
                    expired_at: "",
                    webhook_media_quota:"0"
                },
                statistics: {
                    message_sent: 0,
                    message_pending: 0,
                    message_failed: 0,
                    incoming_message: 0,
                },
                contact: 0,
                filter: {
                    date: moment().format("YYYY-MM-DD"),
                },
                chartData: {
                    labels: ['Terkirim', 'Pending', 'Gagal'],
                    datasets: [{
                        backgroundColor: ['#20c997', '#fa8b0c', '#e24c4b'],
                        data: [0, 0, 0]
                    }]
                },
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false
                },
                announcementText: null,
                popup: {
                    title: null,
                    file: null,
                    text: null
                }
            }
        },
        watch: {
            filter: {
                handler(val) {
                    this.fetchStatistics(moment.utc(val.date).local().format("YYYY-MM-DD"))
                },
                deep: true
            },
        },
        mounted() {
            this.fetchPacakge()
            this.fetchStatistics()
            this.fetchAnnouncement()
            this.fetchPopup()
        },
        methods: {
            async fetchStatistics(date = null) {
                try {
                    let p = await this.$store.dispatch('user_statistics', {
                        date: date ?? this.filter.date
                    })
                    this.statistics = p.data.data.statistics
                    this.contact = p.data.data.contact
                    this.chartData.datasets[0].data = [this.statistics.message_sent, this.statistics
                        .message_pending, this.statistics.message_failed
                    ]
                } catch (err) {
                    console.log(err)
                }
            },
            async fetchPacakge() {
                this.myPackageLoading = true
                try {
                    let p = await this.$store.dispatch('user_myPackage')
                    p = p.data.data.user_package
                    this.myPackage.name = p.package_data.name
                    this.myPackage.pesan = p.message_quota == "-1" ? "Unlimited" : p.message_quota
                    this.myPackage.device = p.max_device == "-1" ? "Unlimited" : p.max_device
                    this.myPackage.customer_service = p.max_cs == "-1" ? "Unlimited" : p.max_cs
                    this.myPackage.campaign = p.max_campaign == "-1" ? "Unlimited" : p.max_campaign
                    this.myPackage.order_online = p.max_order_online == "-1" ? "Unlimited" : p.max_order_online
                    this.myPackage.link_rotator = p.max_link_rotator == "-1" ? "Unlimited" : p.max_link_rotator
                    this.myPackage.chat_widget = p.max_chat_widget == "-1" ? "Unlimited" : p.max_chat_widget
                    this.myPackage.expired_at = moment.unix(p.expired_at / 1000).format('D MMMM YYYY')
                    this.myPackage.webhook_media_quota = (parseInt(p.webhook_media_quota)/1000000) + " GB"
                } catch (err) {
                    console.log(err)
                }
                this.myPackageLoading = false
            },
            async fetchAnnouncement() {
                try {
                    let announcement = await this.$store.dispatch('admin_announcement')
                    this.announcementText = announcement?.data?.data?.announcement?.text ?? null
                } catch (err) {
                    console.log(err)
                }
            },
            async fetchPopup() {
                try {
                    let popupLocal = await this.getWithExpiry("popup")

                    if(popupLocal) return true

                    let popup = await this.$store.dispatch('admin_popup')

                    if(popup?.data?.data?.popup?.id != 0){
                        this.popup = popup?.data?.data?.popup
                        this.$modal.toggle("Popup");

                        this.setWithExpiry("popup", true, 1000 * 60 * 60 * 24)
                    }
                } catch (err) {
                    console.log(err)
                }
            },
            hidePopup(){
                this.$modal.toggle("Popup");
            },
            setWithExpiry(key, value, ttl) {
                const now = new Date()

                // `item` is an object which contains the original value
                // as well as the time when it's supposed to expire
                const item = {
                    value: value,
                    expiry: now.getTime() + ttl,
                }
                localStorage.setItem(key, JSON.stringify(item))
            },
            getWithExpiry(key) {
                const itemStr = localStorage.getItem(key)
                // if the item doesn't exist, return null
                if (!itemStr) {
                    return null
                }
                const item = JSON.parse(itemStr)
                const now = new Date()
                // compare the expiry time of the item with the current time
                if (now.getTime() > item.expiry) {
                    // If the item is expired, delete the item from storage
                    // and return null
                    localStorage.removeItem(key)
                    return null
                }
                return item.value
            }
        }
    }
</script>

<style scoped>
    .element-top-right {
        position: absolute;
        top: 20px;
        right: 20px;
        z-index: 10;

    }
</style>
